import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Article from '~/src/components/Article';

import Block from '~/src/components/Block';
import Figure from '~/src/components/Figure';

import articleFragment from '~/src/pages';

export const frontmatter = {
  id: 'ramp',
  isWork: true,
  title: 'Bringing the insurance industry to 2019.',
  subtitle: 'ramp',
  date: '2019-06-01',
  cover: './ramp-hero.jpg',
  path: '/work/ramp/',
  caption: 'A SaaS enterprise insurance platform. ',
  excerpt: `This was a fun and challenging project, span across all of 2019 with multiple feedback loops in the design process.`,
  details: [
    {
      title: 'Responsibility',
      description: 'Identity, Product, Design',
    },
    {
      title: 'Context',
      description: 'Sketch + Abstract, in a distributed setup.',
    },
    {
      title: 'Date',
      description: '2019',
    },
  ],
};

export default props => (
  <Article {...props}>
    <Block mobilePull>
      <p>
      One of the main challanges I had in 2019 was to understand the how the filings in the insurance industry work, in order to be able to design an efficient virtual process. 
      When I had the first contact with the team at IRC, I never thought it would be that hard to understand all of the tiny parts that would prove to be valuable to the end-user.
      </p>
    </Block>
    <Block align="right">
      <p>
        Without any competitors, going on a path where no one went before, we sat down and sketched a really bad wireframe of how the product
        would work, highlited the most important pieces and started with a design sprint. 
      </p>
    </Block>

    <hr />

    <Block full pull align="center">
      <h2>Identity</h2>
      <p>
        The final product was in need of a visual identity to gain trust in a tough market.
      </p>
    </Block>
    <Block full>
      <Figure fluid={props.data.wireframes} />
    </Block>

    
    <Block vc align="left">
      <h3>It's a serious business</h3>
      <p>
        As we made progress on the platform, we started experimenting with the identity. The initial attempts were bad and looked 
        in this context, but bit-by-bit we began to define the core values and the expected tone. After some feedback loops, ramp had a new face.
      </p>
    </Block>

    <Block vc align="right">
      <h3>Deliverables</h3>
      <p>
        For the final deliverables, I worked with the team at ramp to polish all the visual assets they'd needed, 
        the general startup merch, social media assets and a press kit that proved to be handy and effective when they were covered in the news.
      </p>
    </Block>

  </Article>
);

export const query = graphql`
  query GatsbyImageRamp {
    wireframes: file(relativePath: { eq: "./ramp/brand.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
